<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="人员基础信息"
          :head-btn-options="headTopBtnOptions"
          @head-goback="handleHeadGoback"
        />
        <form-layout
          ref="formLayout"
          :column="formColumn"
          :dataForm.sync="nodeData"
        ></form-layout>
      </el-main>
    </el-container>
    <el-container>
      <el-main>
        <head-layout
          head-title="证件"
          :head-btn-options="headBtnOptions"
          @head-upload="handleUpload"
        />
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :editType="inline"
          @row-view="rowView"
          @row-download="rowDownload"
          @row-edit="rowEdit"
          @row-remove="rowRemove"
          @row-setting="rowSetting"
        >
          <template #fileName="{ row, index }">
            <!-- <span class="file-name" @click="rowView(row,index)">{{ row.fileName }}</span> -->
            <el-link type="primary" @click="rowView(row, index)">{{
              getFileName(row.fileName)
            }}</el-link>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog title="设置提醒" :visible.sync="dialogVisible">
      <span style="margin-right: 10px">提前提醒天数：</span>
      <el-input-number v-model="dateRemind" :min="1"></el-input-number>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog title="证件查看" :visible.sync="imageDiavlog">
      <pdf :src="fileUrl.src" v-if="fileUrl.type == 'pdf'"></pdf>
      <el-image :src="fileUrl.src" v-else></el-image>
    </el-dialog>
    <cedForm ref="cedForm" @callback="onLoad" />
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  employeesLineList,
  employeesLineSubmit,
  employeesLineRemove,
  getDict,
} from "@/api/responsibility/licenceEmployees";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
import { dateFormat } from "@/util/date";
import pdf from "@/components/pdf/pdf.vue";
import cedForm from "./cedForm.vue";

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    pdf,
    cedForm,
  },

  data() {
    return {
      nodeData: {},
      pageType: "",
      tableData: [],
      tableLoading: false,
      query: {},
      dialogVisible: false,
      dateRemind: 1,
      dialogLoading: false,
      settingId: "",
      // headers: { Authorization: this.$store.getters.token },
      fileUrl: "",
      imageDiavlog: false,
      regoLocalList: [],
    };
  },

  created() {
    getDict("rego_local").then((res) => {
      this.regoLocalList = res.data.data;
    });
    this.nodeData = JSON.parse(this.$route.query.row);
    this.pageType = this.$route.query.type;
    this.onLoad();
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
        downloadBtn: this.vaildData(
          this.permission.eduquestions_download,
          true
        ),
        settingBtn: this.vaildData(this.permission.eduquestions_setting, true),
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.pageType == "edit") {
        buttonBtn.push({
          label: "上传",
          emit: "head-upload",
          type: "button",
          icon: "",
          btnOptType: "upload",
        });
      }
      return buttonBtn;
    },
    headTopBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push({
        label: "返回",
        emit: "head-goback",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },

    formColumn() {
      return [
        {
          label: "姓名",
          prop: "userName",
          labelWidth: 120,
          span: 8,
          disabled: true,
        },
        {
          label: "所属组织",
          prop: "orgName",
          labelWidth: 120,
          span: 16,
          disabled: true,
        },
      ];
    },

    tableOptions() {
      return {
        customRowAddBtn: false,
        menuWidth: 260,
        selection: false,
        column: [
          {
            label: "证件名称",
            prop: "fileName",
            align: "center",
            overHidden: true,
            slot: true,
          },
          {
            label: "证件类型",
            prop: "cardType",
            align: "center",
            type: "cascader",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary-tree?code=card_type`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            // showAllLevels: false,
            // emitPath: false
          },
          {
            label: "证件编号",
            prop: "certificateCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "发证单位",
            prop: "certificateUnit",
            align: "center",
            overHidden: true,
          },
          {
            label: "持证方式",
            prop: "holdMode",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hold_mode`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            formatter: (row, value, column) => {
              return column == -1 ? "" : column;
            },
          },
          {
            label: "证件状态",
            prop: "certificateState",
            align: "center",
            overHidden: true,
            type: "select",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=certificate_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "注册地",
            prop: "regoLocal",
            align: "center",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=rego_local`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "发证日期",
            prop: "startDate",
            align: "center",
            overHidden: true,
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "到期日期",
            prop: "endDate",
            align: "center",
            overHidden: true,
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
          },
          {
            label: "上传人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn(row) {
      let buttonBtn = [];
      if (this.permissionList.viewBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.viewBtn`),
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.downloadBtn) {
        buttonBtn.push({
          label: "下载",
          emit: "row-download",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.editBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.settingBtn) {
        buttonBtn.push({
          label: "设置提醒",
          emit: "row-setting",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
  },

  methods: {
    handleHeadGoback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    onLoad(params = {}) {
      this.tableLoading = true;
      employeesLineList({ ...params, controlHeadId: this.nodeData.id }).then(
        (res) => {
          const data = res.data.data;
          this.tableData = data
            ? data.map((val) => {
                val.holdMode = val.holdMode.toString();
                return val;
              })
            : [];
          this.tableLoading = false;
          this.$refs.gridLayOut.selectionClear();
        }
      );
    },

    rowEdit(row, index) {
      this.$refs.cedForm.init(row);
    },
    rowRemove(row, index) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (row.id) {
          employeesLineRemove(row.id).then(() => {
            this.onLoad();
            this.$message.success("操作成功！");
          });
        } else {
          this.tableData.splice(index, 0);
        }
      });
    },
    rowSave(row, index) {
      const {
        cardType,
        startDate,
        endDate,
        fileName,
        files,
        regoLocal,
        certificateCode,
        certificateUnit,
        holdMode,
      } = row;
      const today = new Date(dateFormat(new Date(), "yyyy-MM-dd"));
      const star = new Date(startDate);
      const end = new Date(endDate);
      if (!cardType || !startDate || !endDate || !regoLocal) {
        this.$message.warning("证件类型、注册地、发证日期、到期日期均不能为空");
      } else if (end < star) {
        this.$message.warning("到期日期不能早于发证日期");
      } else {
        const data = {
          cardType,
          startDate,
          endDate,
          fileName,
          files,
          regoLocal,
          controlHeadId: this.nodeData.id,
          belongOrgId: this.nodeData.orgId,
          certificateCode,
          certificateUnit,
          holdMode,
        };
        if (row.id) {
          data.id = row.id;
        }
        data.certificateState = today > end ? 2 : 1;
        employeesLineSubmit(data).then(() => {
          this.$message.success("操作成功");
          this.$refs.gridLayOut.$refs.grid.rowCancel(row, index);
          this.onLoad();
        });
      }
    },
    rowDownload(row) {
      downloadFileBlob(row.files, row.fileName);
    },
    rowView(row) {
      let pos = row.files.lastIndexOf(".");
      const type = row.files.slice(pos + 1, row.files.length);
      this.fileUrl = { src: row.files, name: row.fileName, type };
      this.imageDiavlog = true;
    },

    rowSetting(row) {
      this.dateRemind = row.dateRemind ? row.dateRemind : 1;
      this.settingId = row.id;
      this.dialogVisible = true;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleSave() {
      const { dateRemind, settingId } = this;
      employeesLineSubmit({ dateRemind, id: settingId }).then(() => {
        this.$message.success("操作成功");
        this.onLoad();
        this.handleCancel();
      });
    },

    handleUpload() {
      this.$refs.cedForm.init({
        controlHeadId: this.nodeData.id,
        belongOrgId: this.nodeData.orgId,
      });
      // this.$refs.upload.$el.click()
    },
    getFileName(name) {
      let pos = name.lastIndexOf(".");
      return name.slice(0, pos);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
  label {
    width: 100px;
  }
}
</style>
