<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" :title="title">
            <avue-form 
                :option="formOption"
                ref="avueForm"
                v-model="dataForm"
                @submit="handleSubmit"
                v-loading="loading"
            >
                <template slot="cardType">
                    <el-cascader 
                        placeholder="请选择证件类型" 
                        :disabled="type == 'view'" 
                        ref="cardType" 
                        style="width:100%" 
                        v-model="dataForm.cardType" 
                        
                        :options="typeList" 
                        :props="{label: 'dictValue', value: 'dictKey',}"
                        @change="handleChangeType"
                    >
                    </el-cascader>
                </template>
                <template slot="fileName">
                    <el-input v-model="fileName" ref="fileName" @focus="uploadFile">
                        <el-button slot="append" @click="handleOcr" :disabled="ocrBtn">智能识别</el-button>
                    </el-input>
                </template>
            </avue-form>
            <template slot="footer">
                <el-button size="small" @click="dialogVisible = false" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
            </template>
        </el-dialog>
        <el-upload 
            class="upload-demo" 
            ref="fileUpload"
            action='api/sinoma-resource/oss/endpoint/put-file-attach-name-file' 
            :headers="headers" 
            file 
            :limit="1"
            :show-file-list="false" 
            :on-success="handleUploadSuccess" 
            accept="image/*,.pdf">
            <el-button size="small" style="display: none;" ref="upload"></el-button>
        </el-upload>
    </div>
</template>

<script>
import { enterpriseOCR } from '@/api/responsibility/licenceEnterprise'
import { employeesLineSubmit } from '@/api/responsibility/licenceEmployees'

import formLayout from '@/views/components/layout/form-layout'
import {dateFormat} from '@/util/date'
import {getDictionaryTreeCode} from '@/api/system/dictbiz.js'
export default {
    components: {
        formLayout,
    },
    data () {
        return {
            dialogVisible: false,
            title: '',
            dataForm: {
                fileName: '',
                files: '',
                cardType: '',
                certificateCode: '',
                certificateUnit: '',
                holdMode: '',
                regoLocal: '',
                startDate: '',
                endDate: '',
                belongOrgId: '', // 隶属组织id	
                controlHeadId: '',
            },
            typeList: [],
            formOption: {
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: "证件类型",
                        prop: "cardType",
                        type: 'cascader',
                        rules: [{ required: true, message: '请选择证件类型',trigger:'change'}],
                        formslot: true,
                        span: 12,
                    },
                    {
                        label: "证件名称",
                        prop: "fileName",
                        align: "center",
                        span: 12,
                        formslot: true,
                        rules: [{ required: true, message: '请点击上传证件',trigger:'change'}],
                    },
                    {
                        label: "证件编号",
                        prop: "certificateCode",
                        span: 12,
                        maxlength: 255,
                    },
                    {
                        label: "发证单位",
                        prop: "certificateUnit",
                        span: 12,
                        maxlength: 255,
                    },
                    {
                        label: "持证方式",
                        prop: "holdMode",
                        type: 'select',
                        dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hold_mode`,
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        span: 12,
                    },
                    {
                        label: "注册地",
                        prop: "regoLocal",
                        type: 'select',
                        dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=rego_local`,
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        span: 12,
                        rules: [{ required: true, message: '请选择注册地',trigger:'change'}],
                    },
                    {
                        label: "发证日期",
                        prop: "startDate",
                        rules: [{ required: true, message: '发证时间'}],
                        type: 'date',
                        format: 'yyyy-MM-dd', // 设置日期格式
                        valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                        span: 12,
                    },
                    {
                        label: "到期日期",
                        prop: "endDate",
                        align: "center",
                        rules: [{ required: true, message: '到期时间'}],
                        type: 'date',
                        format: 'yyyy-MM-dd', // 设置日期格式
                        valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                        span: 12,
                    },
                ]
            },
            headers: { Authorization: this.$store.getters.token },
            fileName: '',
            loading: false,
            ocrBtn: true,
        }
    },

    mounted () {
        this.getDic()
    },

    methods: {
        init (data) {
            for (let key in this.dataForm){
                if(key in data){
                    if(key == 'cardType') {
                        this.dataForm.cardType = data.cardType.split(',')
                    } else if (key == 'holdMode'){
                        this.dataForm.holdMode = data.holdMode==-1 ? '' : data.holdMode
                    } else {
                        this.dataForm[key] = data[key]
                    }
                } else {
                    this.dataForm[key] = ''
                }
            }
            this.handleChangeType(this.dataForm.cardType)
            this.fileName = this.dataForm.fileName ? this.getFileName(this.dataForm.fileName) : ''
            if(data.id)this.dataForm.id = data.id
            this.title = data.id ? '职员证照编辑' : '职员证照新增'
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.avueForm.clearValidate()
            })
        },

        getDic() {
            getDictionaryTreeCode('card_type').then((res) => {
                // console.log(res.data.data)
                this.typeList = res.data.data;
            })
        },

        uploadFile () {
            if(this.dataForm.cardType){
                this.$refs.upload.$el.click()
            } else {
                this.$message.warning('请先选择证照类型')
            }
            this.$refs.fileName.blur()
        },

        handleUploadSuccess(res, file, fileList) {
            if (res && res.code == 200) {
                this.dataForm.files = file.response.data.link
                this.dataForm.fileName = file.name
                this.fileName = this.getFileName(file.name)
            }
            this.$refs.fileUpload.clearFiles()
        },

        getFileName(name) {
            let pos = name.lastIndexOf(".")
            return name.slice(0, pos)
        },

        handleSubmit () {
            this.$refs.avueForm.validate((valid, done) => {
                if(valid) {
                    // const {cardType, startDate, endDate, fileName, files, certificateCode, certificateUnit,belongOrgId} = this.dataForm
                    const {cardType, startDate, endDate, fileName, files, regoLocal, certificateCode, certificateUnit, holdMode,controlHeadId,belongOrgId} = this.dataForm

                    const today = new Date(dateFormat(new Date(),'yyyy-MM-dd'))
                    const star = new Date(startDate)
                    const end = new Date(endDate)
                    if(end < star) {
                        this.$message.warning('到期日期不能早于发证日期')
                        done()
                    } else {
                        // const data = {cardType:cardType.toString(), startDate, endDate, fileName, files, certificateCode, certificateUnit, belongOrgId}
                        const data = {cardType:cardType.toString(), startDate, endDate, fileName, files, regoLocal, controlHeadId,belongOrgId, certificateCode, certificateUnit, holdMode}

                        data.certificateState = today>end ? 2 : 1
                        if(this.dataForm.id){ 
                            data.id = this.dataForm.id
                        } else {
                            data.dateRemind = 90
                        }
                        employeesLineSubmit(data).then(() => {
                            this.$message.success('操作成功')
                            this.$emit('callback')
                            this.dialogVisible = false
                            done();
                        })
                        .catch((err) => {
                            done();
                        })
                    }
                }
            })
        },

        handleSave () {
            this.$refs.avueForm.submit()
        },

        getDate (dateStr) {
            const parts = dateStr.match(/(\d{4})年(\d{1,2})月(\d{1,2})日/);
            const year = parts[1];
            let month = parts[2];
            let day = parts[3];
            return `${year}-${month}-${day}`
        },

        handleChangeType (val) {
            if(val){
                this.ocrBtn = !(val[val.length-1] == 'QYZZLX-06' || val[val.length-1] == 'RYZZLX-28')
            } else {
                this.ocrBtn = true
            }
        },

        handleOcr () {
            if(this.dataForm.files){
                let pos = this.dataForm.files.lastIndexOf(".")
                let type = this.dataForm.files.slice(pos,this.dataForm.files.length)
                this.$refs.fileUpload.clearFiles();
                let len = this.dataForm.cardType.length
                const certificateType = this.dataForm.cardType[len-1]
                // 提示词编码： 1.安全生产许可证：2_AQSCXKZ 2.中级注册安全工程师：2_ZJZCAQGCS 3.高级注册安全工程师：
                // 证书类型： 1.安全生产许可证：QYZZLX-06 2.中级注册安全工程师：RYZZLX-28 3.高级注册安全工程师：RYZZLX-29
                const cueWordCode = certificateType == 'QYZZLX-06' ? '2_AQSCXKZ' : 
                                    certificateType == 'RYZZLX-28' ? '2_ZJZCAQGCS' : 
                                    certificateType == 'RYZZLX-29' ? '' : ''
                this.loading = true
                enterpriseOCR({file: this.dataForm.files, certificateType,cueWordCode}).then(res=>{
                // enterpriseOCR({file: this.dataForm.files, certificateType: 'QYZZLX-06',cueWordCode: '2_AQSCXKZ'}).then(res=>{
                    if(res.data){
                        const data = res.data.data
                        this.fileName = data.fileName
                        if(data.fileName)this.dataForm.fileName = `${data.fileName}${type}`
                        if(data.certificateCode)this.dataForm.certificateCode = data.certificateCode
                        if(data.certificateUnit)this.dataForm.certificateUnit = data.certificateUnit
                        if(data.endDate)this.dataForm.endDate = this.getDate(data.endDate)
                        if(data.startDate)this.dataForm.startDate = this.getDate(data.startDate)
                    }
                    this.loading = false
                }).catch(()=>{
                    this.loading = false
                })
            } else {
                this.$message.warning('请先上传文件')
            }
        }
    }
}
</script>

<style>

</style>